/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//dev

/* const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:4d064097-99a4-4950-8740-5fcdef25577e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_5JPttAiOW",
    "aws_user_pools_web_client_id": "2pokrfgk7a2cuvsr8tbc6jm8nd",
    "oauth": {}
};
*/

//prod
const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:0ca0b2b4-cb69-4192-b6a4-87f8ca105004",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qp9jtWUEW",
    "aws_user_pools_web_client_id": "62u2cnf59v5q9pkctlgfhrpj9a",
    "oauth": {}
};



export default awsmobile;
