<template>
  <div id="app">
    <sui-menu inverted>
      <sui-container>
        <a href="#" is="sui-menu-item" class="header">
          Bisturi
        </a>

        <router-link is="sui-menu-item" to="/patients">Pacientes</router-link>
        <!-- <router-link is="sui-menu-item" to="/protocolo">Protocolo</router-link> -->

        <p is="sui-menu-item" position="right" v-if="isLocal">LOCAL</p>
        <amplify-sign-out v-if="authState === 'signedin' && user" is="sui-menu-item" position="right" />
        
      </sui-container>
    </sui-menu>
    
    <sui-container class="main" text>
      <div v-if="authState !== 'signedin'">Você não está autenticado.</div>
      <!-- <amplify-auth-container> -->
      <amplify-authenticator>
        <div v-if="authState === 'signedin' && user">
          <router-view></router-view>
        </div>
     </amplify-authenticator>
    <!-- </amplify-auth-container> -->
    </sui-container>
    <portal-target name="semantic-ui-vue" />
  </div>
  
</template>

<script>

import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { API_ENDPOINT } from './BisturiDB'
//import "./BisturiDB"
//import { listProtocolos } from './BisturiDB'

export default {
  name: 'App',
  components: {
    
  },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;

      //this.redirectIfAuth();
    })

  },
  data() {
    return {
      user: undefined,
      authState: undefined
    }
  },
  computed: {
    isLocal: {
      get() { return API_ENDPOINT.includes("127.0.0.1"); }
    }
  },
  async mounted() {
    //let protocolos = await listProtocolos();
    //console.log(protocolos);

    //await getProtocolo("d0934292-6bc8-4cf2-9739-fd98750b93f4");
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      //this.redirectIfAuth();
    });
  },
  redirectIfAuth(){
    if(this.authState === 'signedin') {
      this.$router.push({ name: 'Patients' });
    }
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  }
}
</script>

<style>
</style>
