/* eslint-disable no-unused-vars */
//export const API_ENDPOINT = "http://127.0.0.1:5000"
//export const API_ENDPOINT = "https://79u7ohflo0.execute-api.us-east-2.amazonaws.com/dev"
export const API_ENDPOINT = "https://qocipnaoqe.execute-api.us-east-2.amazonaws.com/dev"

import { Auth } from 'aws-amplify';

async function fetchWithAuth(input, init) {
  let credentials = await Auth.currentSession();
  let idToken;
  if(credentials) {
    idToken = credentials.idToken.jwtToken;
  }
  
  if(init) {
    init.headers.Authorization = idToken
  } else {
    init = { headers: { Authorization: idToken } };
  }

  return fetch(input, init);
}

export function isLocalAPI() {
  return API_ENDPOINT.includes("127.0.0.1");
}

export async function listProtocolForms() {
  return fetchWithAuth(API_ENDPOINT + '/forms')
    .then(response => response.json());

}
export async function getProtocolForm(protocoloId) {
  return fetchWithAuth(API_ENDPOINT + '/form/' + protocoloId)
    .then(response => response.json());
}

export async function listPatients() {
  
  return fetchWithAuth(API_ENDPOINT + '/patients')
    .then(response => response.json());
}

export async function listPatientsLimit(limit, pagenext) {
  
  let pgn = '';
  if(pagenext != undefined) {
    pgn = '&offset=' + pagenext;
  }
  return fetchWithAuth(API_ENDPOINT + '/patients?limit=' + limit + pgn)
    .then(response => response.json());
}

export async function searchPatientsLimit(query, limit, pagenext) {
  let pgn = '';
  if(pagenext != undefined) {
    pgn = '&offset=' + pagenext;
  }
  return fetchWithAuth(API_ENDPOINT + '/patients/search?q=' + encodeURIComponent(query) + '&limit=' + limit + pgn)
    .then(response => response.json());
}

export async function getPatient(patientId) {
  return fetchWithAuth(API_ENDPOINT + '/patient/' + patientId)
    .then(response => response.json());
}

export async function newPatient(exeter, name) {
  let formData = {
    "Exeter": exeter,
    "Name": name
  };
  
  return fetchWithAuth(API_ENDPOINT + '/patient', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json());
}

export async function updatePatient(id, exeter, name) {
  let formData = {
    "Exeter": exeter,
    "Name": name
  };
  
  return fetchWithAuth(API_ENDPOINT + '/patient/' + id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json());
}


export async function getProtocol(recordId) {
  return fetchWithAuth(API_ENDPOINT + '/record/' + recordId)
    .then(response => response.json());
}

export async function saveProtocol(formData, recordId) {
  let url;
  if(recordId != null) {
    url = API_ENDPOINT + '/record/' + String(recordId);
  } else {
    url = API_ENDPOINT + '/record'
  }
  return fetchWithAuth(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json());
}