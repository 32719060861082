<template>
    <p>{{ Label }}</p>
</template>
<script>
export default {
    name: "FieldText",
    props: [
        "Label",
        "mode"
    ]
}
</script>