<template>
  <div>
    <h1><sui-button icon="chevron left" @click="$router.go(-1)" />Novo Paciente</h1>
      <sui-form v-on:submit.prevent="addNewPatient()">
        <sui-form-field>
          <label>Exeter</label>
          <input placeholder="Exeter" v-model="exeter"/>
        </sui-form-field>
        <sui-form-field>
          <label>Nome do paciente</label>
          <input placeholder="Nome do paciente" v-model="patientName" />
        </sui-form-field>
    <sui-message
        v-if="errorMessage"
        header="Erro"
        :content="errorMessage"
        dismissable
        @dismiss="handleDismiss"
    />
    <sui-button type="submit">Salvar</sui-button>
</sui-form>
  </div>
</template>

<script>
/*eslint no-unused-vars: off */
import { newPatient, updatePatient } from '../BisturiDB'

export default {
  name: "NewPatient",
  components: { },
  data() {
    return { 
        errorMessage: null
    }
  },
  props: {
    exeter: { type: String, required: false },
    patientName: { type: String, required: false },
    patientId: { type: Number, required: false }
  }, 
  methods: {
    async addNewPatient() {
        if(this.patientName) {
          if(this.exeter != null && this.exeter.trim() == '') {
            this.exeter = null;
          }

          let result;
          if(this.patientId) {
            result = await updatePatient(this.patientId, this.exeter, this.patientName);
          } else {
            result = await newPatient(this.exeter, this.patientName);
          }

          if('error' in result) {
              this.errorMessage = result.error;
          } else {
              this.$router.push({ name: 'PatientDetail', params: { 'id': result.PatientId }});
          }
        } else {
          //TODO: alterar para validação do Vue
          alert("Preencha os campos Nome do paciente")
        }
    },
    handleDismiss() {
        this.errorMessage = null;
    }
  }
};
</script>