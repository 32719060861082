<template>
    <sui-form-field inline>
        <label>{{ QuestionNumber }}. {{ Label }}</label>
        <span v-if="!isEditable">{{ valueProxyAsBeautifulString }}</span>
        <sui-dropdown
            v-else
            placeholder="Selecione"
            multiple
            selection
            :options="mappedOptions"
            :name="QuestionIdentifier"
            v-model="valueProxy"
        />
        <sui-popup :content="validation.reason" :header="QuestionIdentifier + ' (Multiple)'" v-if="validation.isValid == false">
            <sui-icon name="exclamation circle" slot="trigger"/>
        </sui-popup>
        <sui-label horizontal v-if="debug">
            <sui-popup v-if="debug" hoverable>
                <sui-popup-header>Debug</sui-popup-header>
                <sui-popup-content>
                    <p><strong>Field: </strong>{{ JSON.stringify(this.$props) }}</p>
                    <p><strong>Value: </strong>{{ JSON.stringify(this.valueProxy) }}</p>
                </sui-popup-content>
                <sui-icon name="bug" disabled fitted slot="trigger" />
            </sui-popup>   
        </sui-label>
    </sui-form-field>
</template>
<script>
export default {
    name: "FieldMultiple",
    data(){
        return {
            mappedOptions: this.Options.map(x => {
                if(typeof x == "string") return {text: x, value: x}
                else return { text: Object.values(x)[0], value: Object.keys(x)[0] }
            })
        }
    },
    computed: {
        valueProxy: {
            get() { return this.$attrs.value; },
            set(value) { this.$attrs.value = value; this.$emit('input', value); }
        },
        valueProxyAsBeautifulString: {
            get() {
                if(Array.isArray(this.valueProxy)){
                    return this.valueProxy.join(", ");
                }
                return "";
            }
        },
        isEditable: {
            get() { return this.mode == "edit"; }
        },
        validation: {
            get() {
                let val = this.valueProxy;
                //console.log("val multiple: " + JSON.stringify(this.valueProxy));
                if(val === undefined) return { isValid: true, reason: "undefined" };
                
                //https://stackoverflow.com/a/9204307
                //let success = this.mappedOptions.every((x) => val.includes(x.value))
                //console.log(this.QuestionIdentifier)
                //console.log(val);
                let success = val.every((val) => this.mappedOptions.some(opt => opt.value == val));
                if(!success) {
                    return { isValid: false, reason: "value = " + JSON.stringify(val) + "" }
                }

                return { isValid: true }
            }
        }
    },
    props: [
        "QuestionNumber",
        "QuestionIdentifier",
        "Label",
        "Options",
        "debug",
        "mode"
    ],
    created() {
        if(this.Options == undefined) {
            console.error("Multiple field " + this.QuestionIdentifier + " has undefined Options.")
        }
    }
}
</script>