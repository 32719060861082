import Vue from 'vue'
import PortalVue from 'portal-vue';
import SuiVue from 'semantic-ui-vue';
import App from './App.vue'
import router from './router'
import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import 'semantic-ui-css/semantic.min.css';

Amplify.configure(awsconfig);

Vue.config.productionTip = false
Vue.use(PortalVue);
Vue.use(SuiVue);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
