<template>
    <sui-form-field inline>
        <label>{{ QuestionNumber }}. {{ Label }}</label>
        <!-- atributo label pode ser sim/nao? -->
        <sui-checkbox 
            toggle 
            :name="QuestionIdentifier"
            v-model="valueProxy"
        />
        <sui-label horizontal v-if="debug">
            <sui-popup v-if="debug" hoverable>
                <sui-popup-header>Debug</sui-popup-header>
                <sui-popup-content>
                    <p><strong>Field: </strong>{{ JSON.stringify(this.$props) }}</p>
                    <p><strong>Value: </strong>{{ JSON.stringify(this.valueProxy) }}</p>
                </sui-popup-content>
                <sui-icon name="bug" disabled fitted slot="trigger" />
            </sui-popup>   
        </sui-label>
    </sui-form-field>
</template>
<script>
export default {
    name: "CampoYesNo",
    computed: {
        valueProxy: {
            get() { return this.$attrs.value; },
            set(value) { this.$attrs.value = value; this.$emit('input', value); }
        },
        isEditable: {
            get() { return this.mode == "edit"; }
        }
    },
    props: [
        "QuestionNumber",
        "QuestionIdentifier",
        "Label",
        "debug",
        "mode"
    ]
}
</script>