<template>
  <div>
    <h1 v-if="!loaded"><sui-button icon="chevron left" @click="$router.go(-1)" />Imagens</h1>
    <sui-loader active v-if="!loaded" />
    <div v-if="loaded">
        <h1><sui-button icon="chevron left" @click="$router.go(-1)" />{{ schema.Patient.Name }}</h1> <!-- TODO: adicionar botão para editar nome -->
        <h4 v-if="schema.Patient.ExeterNumber == null">Sem Exeter cadastrado</h4>
        <h4 v-else>Ex{{ schema.Patient.ExeterNumber}}</h4>

        <sui-table compact="very">
          
          <sui-table-header>
            <sui-table-row>
              <sui-table-header-cell collapsing><sui-loader active v-if="updatingFileList" inline size="mini"/></sui-table-header-cell>
              <sui-table-header-cell>Nome do arquivo</sui-table-header-cell>
              <sui-table-header-cell>
                <input 
                  type="file" 
                  @change="uploadFile($event)"
                  id="fileupload"
                  style="width: 0.1px;	height: 0.1px; opacity: 0; overflow: hidden; position: absolute; z-index: -1;" 
                  />
                  <!-- style="display:none;"/> -->
                <label for="fileupload" v-show="!uploading">
                  <sui-label color="green" size="large">
                    <sui-icon name="upload"/>
                    Enviar
                  </sui-label>
                </label>
                <sui-progress state="active" indicating :percent="uploadPercent" v-if="uploading"/>
                <!--   -->
              </sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>
          <sui-table-body>
            <sui-table-row v-for="file in fileList" :key="file.Key">
              <sui-table-cell collapsing><sui-icon :name="file.Icon" /></sui-table-cell>
              <sui-table-cell><a href="#" @click="createAndOpenSignedURL(file)">{{ file.Filename }}</a></sui-table-cell>
              <sui-table-cell><sui-button icon="trash alternate" color="red" size="mini" @click="deleteFile(file)"/></sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
        <p v-if="fileList.length == 0">Nenhuma imagem encontrada.</p>
    </div>
  </div>
</template>

<script>
const BUCKET = "bisturi-imagens-prod";
const KEY_PREFIX = "tier/"
const BUCKET_REGION = "us-east-2";

/*eslint no-unused-vars: off */
import { getPatient } from '../BisturiDB';
import { Auth } from 'aws-amplify';
import { S3, S3Client, GetObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3"; 
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
export default {
  name: "PatientImages",
  components: { },
  data() {
    return {
      loaded: false,
      schema: null,
      fileList: null,
      uploading: false,
      uploadPercent: 0.0,
      updatingFileList: false
    }
  },
  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    getPrefixForPatient() {
      if(this.schema.Patient.JsonData != undefined && this.schema.Patient.JsonData.UsePatientIdAsReference) {
        return KEY_PREFIX + "P" + this.schema.Patient.PatientId + "/";
      }
        
      return KEY_PREFIX + "Ex" + this.schema.Patient.ExeterNumber + " - " + this.schema.Patient.Name + "/";
    },
    getPrefixForList() {
      if(this.schema.Patient.JsonData != undefined && this.schema.Patient.JsonData.UsePatientIdAsReference) {
        return KEY_PREFIX + "P" + this.schema.Patient.PatientId + "/";
      }
        
      return KEY_PREFIX + "Ex" + this.schema.Patient.ExeterNumber + " -";
    },
    async deleteFile(file) {
      let confirm = window.confirm("Tem certeza que deseja excluir o arquivo " + file.Filename + "?")
      if(!confirm) {
        return;
      }
      let authCredentials = await Auth.currentUserCredentials();
      let s3client = new S3({ region: BUCKET_REGION, credentials: authCredentials});
      await s3client.deleteObject({
        Bucket: BUCKET,
        Key: file.Key
      });
      this.updateFileList();
    },
    async uploadFile(ev) {
      console.log(ev);
      // from event
      // todo: multiple file upload
      let file = ev.target.files[0];

      let s3prefix = this.getPrefixForPatient();

      //todo: normalize filename
      let uplObjKey = s3prefix + file.name;

      let authCredentials = await Auth.currentUserCredentials();
      let s3client = new S3({ region: BUCKET_REGION, credentials: authCredentials});
      let command = new PutObjectCommand({
        Bucket: BUCKET,
        Key: uplObjKey,
        ContentType: this.typeForExtension(file.name).Type
      });
      
      let presignedUrl = await getSignedUrl(s3client, command, { expiresIn: 300 });
      console.log(presignedUrl);

      const reader = new FileReader();
      this.uploading = true;
      this.uploadPercent = 0;
      const xhr = new XMLHttpRequest();
      
      this.xhr = xhr;

      const self = this;
      this.xhr.upload.addEventListener("progress", function(e) {
            if (e.lengthComputable) {
              const percentage = Math.round((e.loaded * 100) / e.total);
              self.uploadPercent = percentage;
            }
          }, false);

      xhr.upload.addEventListener("load", function(e){
              //upload concluído
              self.uploadPercent = 100;
              self.uploading = false;
              self.updateFileList();
          }, false);

      
      xhr.open("PUT", presignedUrl);
      reader.onload = function(evt) {
        xhr.overrideMimeType(self.typeForExtension(file.name).Type);
        xhr.send(evt.target.result);
      };
      reader.readAsArrayBuffer(file);

    },
    async createAndOpenSignedURL(file) {
      let authCredentials = await Auth.currentUserCredentials();
      let s3client = new S3({ region: BUCKET_REGION, credentials: authCredentials});
      let command = new GetObjectCommand({
        Bucket: BUCKET,
        Key: file.Key,
        ResponseContentType: this.typeForExtension(file.Key).Type
      });
      
      let url = await getSignedUrl(s3client, command, { expiresIn: 300 });
      //console.log(url);
      window.open(url);
    },
    async updateFileList() {
      this.updatingFileList = true;
      let images = await this.listObjects();
      this.fileList = images;
      this.updatingFileList = false;
    },
    typeForExtension(filename) {
      const extToType = {
        "jpg": {Type: "image/jpeg", Icon: "image outline"}, 
        "jpeg": {Type: "image/jpeg", Icon: "image outline"},
        "jpe": {Type: "image/jpeg", Icon: "image outline"},
        "png": {Type: "image/png", Icon: "image outline"},
        "mp4": {Type: "video/mp4", Icon: "film"},
        "wmv": {Type: "video/x-ms-wmv", Icon: "film"},
        "mov": {Type: "video/quicktime", Icon: "film"}
      };

      let type = extToType[filename.substr(filename.lastIndexOf('.') + 1).toLowerCase()]
      if (type === undefined) {
        type = { Type: "application/octet-stream", Icon: "file outline" };
      }
      return type;
    },
    async listObjects() {
      let authCredentials = await Auth.currentUserCredentials();
      let s3client = new S3({ region: BUCKET_REGION, credentials: authCredentials});
      let objects = await s3client.listObjectsV2({
        Bucket: BUCKET,
        Prefix: this.getPrefixForList()
      });

      let images = []
      
      if(objects.KeyCount > 0) {
        for(let obj of objects.Contents) {
          let type = this.typeForExtension(obj.Key)
          console.log(obj.Key);
          images.push({
            Key: obj.Key, 
            Filename: obj.Key.substr(obj.Key.lastIndexOf('/') + 1),
            Type: type.Type,
            Icon: type.Icon
          });
        }
      } 

      return images;
    },
    async fetchData() {
      let patientId = this.$route.params.id;

      this.schema = await getPatient(patientId);
      
      //console.log()
      
      //console.log(objects);
      await this.updateFileList();
      this.loaded = true;
    }
  }
};
</script>`