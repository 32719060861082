import Vue from "vue";
import Router from "vue-router";
//import Home from "../Home.vue";
import Record from "../record/Record.vue";
import Patients from "../patients/Patients.vue";
import NewPatient from "../patients/NewPatient.vue";
import PatientDetail from "../patients/PatientDetail.vue";
import PatientImages from "../patients/PatientImages.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //{
    //  path: "/",
    //  name: "Home",
    //  component: Home
    //},
    { path: '/', redirect: { name: 'Patients' }},
    {
      path: "/record/:action/:id/:target?",
      name: "Record",
      component: Record
    },
    {
      path: "/patients",
      name: "Patients",
      component: Patients
    },
    {
      path: "/patient/new",
      name: "NewPatient",
      component: NewPatient,
      props: true
    },
    {
      path: "/patient/:id",
      name: "PatientDetail",
      component: PatientDetail
    },
    {
      path: "/patient/:id/images",
      name: "PatientImages",
      component: PatientImages
    }
  ]
});