<template>
  <div>
    <sui-loader active v-if="!loaded" />
    <sui-grid :columns="2" v-if="loaded">
      <sui-grid-row>
        <sui-grid-column floated="left">
          <h1><sui-button icon="chevron left" @click="$router.go(-1)" />{{ schema.formName }}</h1>
        </sui-grid-column>
        <sui-grid-column floated="right">
          <sui-button-group v-if="canShowEditButton">
            <sui-button content="Editar" icon="edit" label-position="left" color="green" v-on:click="onEditButton();" />
          </sui-button-group>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
    <form-builder :schema="schema" :value.sync="formData" v-if="loaded" :debug="isLocal" :mode="mode" />
    <sui-message
        v-if="errorMessage"
        header="Erro"
        :content="errorMessage"
        dismissable
        @dismiss="handleDismiss"
        id="errorMessage"
    />
    <sui-button v-on:click="saveForm()" v-if="canSave">Salvar</sui-button>
  </div>
</template>

<script>
/*eslint no-unused-vars: off */
import { getProtocol, getProtocolForm, saveProtocol, isLocalAPI } from '../BisturiDB'
import FormBuilder from "./components/FormBuilder";
export default {
  name: "Record",
  components: { FormBuilder },
  data() {
    return {
      loaded: false,
      formData: {},
      schema: null,
      params: {},
      mode: null, //view / edit
      errorMessage: null
    }
  },
  computed: {
    isLocal: {
      get() { return isLocalAPI(); }
    },
    canSave: {
      get() { if(this.loaded && this.mode == "edit") return true; return false; }
    },
    canShowEditButton: {
      get() { if(!this.loaded || this.params.action == "new" || this.mode == "edit") return false; return true; }
    }
  },
  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    async fetchData() {
      //path: "/protocol/:action/:id/:target",

      this.params.action = this.$route.params.action;
      
      if(this.params.action == "new") {
        // /protocol/new/p1/abcde
        this.params.formId = this.$route.params.id;
        this.params.patientId = this.$route.params.target;
        if(this.params.patientId === undefined) {
          console.log("undefined patient id")
        }
        this.mode = "edit"
        
      } else if(this.params.action == "view") {
        // /protocol/view/zxczczx
        let record = await getProtocol(this.$route.params.id)
        this.params.patientId = record.PatientId;
        this.params.recordId = record.RecordId;
        this.params.formId = record.FormId;
        this.formData = record.FormData;
        this.mode = "view";
      } else if(this.params.action == "edit") {
        // /protocol/edit/zxczczx
        let record = await getProtocol(this.$route.params.id)
        this.params.patientId = record.PatientId;
        this.params.recordId = record.RecordId;
        this.params.formId = record.FormId;
        this.formData = record.FormData;
        this.mode = "edit";
      }

      this.schema = await getProtocolForm(this.params.formId);
      this.loaded = true;
    },
    onEditButton() {
      this.mode = "edit";
    },
    handleDismiss() {
        this.errorMessage = null;
    },
    async saveForm() {
      //TODO: add form validation
      
      let form = {
        'FormId': this.params.formId,
        'PatientId': this.params.patientId,
        'FormData': this.formData
      };
      let recordId;
      if(this.params.recordId !== undefined) {
        recordId = this.params.recordId; 
      } else {
        recordId = null;
      }

      let ret = await saveProtocol(form, recordId);

      if(!("error" in ret)){
        this.mode = "view";
      } else {
        //scroll to message
        this.errorMessage = ret.error
        document.getElementById("errorMessage").scrollIntoView();
      }

      console.log(ret);
    }
  }
};
</script>