<template>
    <sui-form-field inline>
        <label>{{ QuestionNumber }}. {{ Label }}</label>
        <!-- atributo label pode ser sim/nao? -->
       {{ ComputedValue }}
       
    </sui-form-field>
</template>
<script>
export default {
    name: "FieldEval",
    props: [
        "QuestionNumber",
        "QuestionIdentifier",
        "Label",
        "ComputedValue",
        "mode"
    ]
}
</script>