<template>
    <sui-form-field inline>
        <label>{{ QuestionNumber }}. {{ Label }}</label>
        <span v-if="!isEditable">{{ friendlyValueProxy }}</span>
        <sui-dropdown
            v-else
            placeholder="Selecione"
            selection
            :options="mappedOptions"
            :name="QuestionIdentifier"
            v-model="valueProxy"
        />
        <sui-popup :content="validation.reason" :header="QuestionIdentifier + ' (Choice)'" v-if="validation.isValid == false">
            <sui-icon name="exclamation circle" slot="trigger"/>
        </sui-popup>
        <sui-popup :content="externalValidationProxy.ForcedVisibilityReason" :header="QuestionIdentifier + ' (Choice)'" v-if="externalValidationProxy.ForcedVisibility">
            <sui-icon name="low vision" slot="trigger"/>
        </sui-popup>
        <sui-label horizontal v-if="debug">
            <sui-popup v-if="debug" hoverable>
                <sui-popup-header>Debug</sui-popup-header>
                <sui-popup-content>
                    <p><strong>Field: </strong>{{ JSON.stringify(this.$props) }}</p>
                    <p><strong>Value: </strong>{{ JSON.stringify(this.valueProxy) }}</p>
                </sui-popup-content>
                <sui-icon name="bug" disabled fitted slot="trigger" />
            </sui-popup>   
        </sui-label>
    </sui-form-field>
</template>
<script>
export default {
    name: "FieldYesNoNull",
    data(){
        return {
            mappedOptions: [
                { text: "Não", value: false },
                { text: "Sim", value: true}
            ]
        }
    },
    computed: {
        valueProxy: {
            get() { return this.$attrs.value; },
            set(value) { this.$attrs.value = value; this.$emit('input', value); }
        },
        friendlyValueProxy: {
            get() { 
                let opt = this.mappedOptions.find(x => x.value === this.valueProxy);
                if(opt) return opt.text;
                return "";
             }
        },
        isEditable: {
            get() { return this.mode == "edit"; }
        },
        validation: {
            get() {
                let val = this.valueProxy;
                if(val === undefined) return { isValid: true, reason: "undefined" };
                if(!this.mappedOptions.map(x => (x.value)).includes(val)) {
                    console.log({ isValid: false, reason: "value = '" + val + "'" })
                    return { isValid: false, reason: "value = '" + val + "'" }
                }
                return { isValid: true }
            }
        },
        externalValidationProxy: {
            get() {
                if(this.ExternalValidation) {
                    return this.ExternalValidation;
                } else {
                    return {}
                }
            }
        }
    },
    props: [
        "QuestionNumber",
        "QuestionIdentifier",
        "Label",
        "Options",
        "debug",
        "ExternalValidation",
        "mode"
    ],
    created() {
    }
}
</script>