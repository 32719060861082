<template>
  <div>
    <h1>Pacientes</h1>
    <sui-grid :columns="3" centered>
      <sui-grid-column>
        <sui-button content="Novo paciente" icon="user plus" label-position="left" @click="$router.push({name: 'NewPatient'});" />
      </sui-grid-column>
      <sui-grid-column>
        <sui-input v-model="search" icon="search" placeholder="Buscar nome ou exeter" v-on:keyup="onSearchKeyup()" />
      </sui-grid-column>
      <sui-grid-column>
      <p v-if="tableLoaded">Total de registros: {{ schema.total }}</p>
      </sui-grid-column>
    </sui-grid>
  <sui-loader active v-if="!tableLoaded" />
  <!-- TODO: verificar posicao do dimmer para só apagar a tabela, e nao a pagina toda -->
  <sui-dimmer :active="loading" inverted>
    <sui-loader />
  </sui-dimmer>
  <!-- END TODO -->
  <sui-table celled compact="very" v-if="tableLoaded">
    <sui-table-header>
      <sui-table-row>
        <sui-table-header-cell collapsing>Exeter</sui-table-header-cell>
        <sui-table-header-cell>Nome</sui-table-header-cell>
      </sui-table-row>
    </sui-table-header>
    <sui-table-body>
      <sui-table-row v-for="patient in schema.results" :key="patient.ExeterNumber">
        <sui-table-cell collapsing text-align="right">{{ patient.ExeterNumber }}</sui-table-cell>
        <sui-table-cell><router-link :to="{ name: 'PatientDetail', params: { id: patient.PatientId }}" >{{patient.Name}}</router-link></sui-table-cell>
      </sui-table-row>
    </sui-table-body>

    <sui-table-footer>
      <sui-table-row>
        <sui-table-header-cell colspan="2">
          <sui-menu pagination>
            <a is="sui-menu-item" icon v-on:click="prevPage()">
              <sui-icon name="left chevron" />
            </a>
            <!-- 
            <a is="sui-menu-item">1</a>
            <a is="sui-menu-item">2</a>
            <a is="sui-menu-item">3</a>
            <a is="sui-menu-item">4</a>
            -->
            <a is="sui-menu-item" icon v-on:click="nextPage()">
              <sui-icon name="right chevron" />
            </a>
          </sui-menu>
        </sui-table-header-cell>
      </sui-table-row>
    </sui-table-footer>
  </sui-table>
  </div>
</template>

<script>
/*eslint no-unused-vars: off */
const PAGE_COUNT = 15;
import { API_ENDPOINT, listPatients, listPatientsLimit, searchPatientsLimit } from '../BisturiDB'
const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
export default {
  name: "Patients",
  components: { },
  data() {
    return {
      tableLoaded: false,
      loading: false,
      schema: null,
      currentOffset: 0,
      search: ""
    }
  },
  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    async prevPage() {
      this.currentOffset -= PAGE_COUNT;
      if(this.currentOffset < 0) this.currentOffset = 0;
      this.loading = true;
      return this.fetchData();
    },
    async nextPage() {
      this.currentOffset += PAGE_COUNT;
      this.loading = true;
      return this.fetchData();
    },
    async fetchData() {
      this.schema = await listPatientsLimit(PAGE_COUNT, this.currentOffset);
      this.tableLoaded = true;
      this.loading = false;
    },
    async onSearchKeyup() {
      //console.log("keyup");
      debounce(async function(that) {
        that.schema = await searchPatientsLimit(that.search, PAGE_COUNT, that.currentOffset);
        that.tableLoaded = true;
        that.loading = false; 
      }, 250)(this);
    }
  }
};
</script>