<template>
    <sui-form-field inline>
        <label 
            :for="QuestionNumber">{{ QuestionNumber }}. {{ Label }}</label>
        <span v-if="!isEditable">{{ valueProxy }}</span>
        <masked-input
            v-else
            type="text"
            :name="QuestionIdentifier"
            :placeholder="Placeholder"
            :mask="maskArray"
            :pipe="pipeFunction"
            v-model="valueProxy"
        />
        &nbsp;
        <label inline>{{ Unit }}</label>
        <sui-label horizontal v-if="debug">
            <sui-popup v-if="debug" hoverable>
                <sui-popup-header>Debug</sui-popup-header>
                <sui-popup-content>
                    <p><strong>Field: </strong>{{ JSON.stringify(this.$props) }}</p>
                    <p><strong>Value: </strong>{{ JSON.stringify(this.valueProxy) }}</p>
                </sui-popup-content>
                <sui-icon name="bug" disabled fitted slot="trigger" />
            </sui-popup>   
        </sui-label>
    </sui-form-field>
</template>
<script>
//@input="$emit('input', $event.target.value)"
/// v-model = textValue
//@input="$emit('input', valueProxy)"
//https://github.com/text-mask/text-mask/tree/master/vue#readme
import MaskedInput from 'vue-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
export default {
    name: "CampoInput",
    components: {
      MaskedInput
    },
    computed: {
        valueProxy: {
            get() { return this.$attrs.value; },
            set(value) { this.$attrs.value = value; this.$emit('input', value); }
        },
        isEditable: {
            get() { return this.mode == "edit"; }
        },
        maskArray() {
            if(this.Mask === undefined) {
                return false;
            } else if (this.Mask == "number") {
                //see https://github.com/text-mask/text-mask/tree/master/addons/
                const numberMask = createNumberMask({
                    prefix: '',
                    suffix: '',
                    includeThousandsSeparator: false,

                });
                return numberMask;
            } else if (this.Mask == "date") {
                //see also pipeFunction()
                return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
            } else if(this.Mask.startsWith("[")) {
                //is an stringified array
                try {
                    let maskStringArray = JSON.parse(this.Mask)
                    let maskArray = []
                    for(let item of maskStringArray)
                    {
                        if(item.startsWith("/") && item.endsWith("/") && item.length > 1 ) { //it's a regex...
                            let cleanedUpRegexString = item.substring(1, item.length - 1);
                            maskArray.push(RegExp(cleanedUpRegexString));
                        } else {
                            maskArray.push(item);
                        }
                    }
                    //console.log(maskArray)
                    return maskArray;
                }
                catch(err) {
                    console.error("Masked Input error: " + err);
                    return false
                }
            }

            //by default, no masking
            return false;
        },
        pipeFunction() {
            if (this.Mask == "date") {
                const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
                return autoCorrectedDatePipe;
            }
            //by default, return false
            return undefined;
        }
    },
    props: [
        "QuestionNumber",
        "QuestionIdentifier",
        "Label",
        "Placeholder",
        "Mask",
        "Unit",
        "debug",
        "mode" 
    ]
}
</script>