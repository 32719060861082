<template>
    <div>
        <sui-form>
            <sui-header dividing>{{ schema.FormName }}</sui-header>
            <div v-for="field in schema.fields" :key="field.FormSequence">
                <component 
                    :is="field.Type"
                    :value="formData[field.QuestionIdentifier]"
                    @input="updateForm(field, $event)"
                    v-bind="field"
                    v-show="field.Show"
                    :debug="debug"
                    :mode="mode"
                />   
                
            </div>
        </sui-form>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import CampoInput from './CampoInput.vue'
import CampoChoice from './CampoChoice.vue'
import CampoYesNo from './CampoYesNo.vue'
import FieldSection from './FieldSection'
import FieldMultiple from './FieldMultiple'
import FieldEval from './FieldEval'
import FieldText from './FieldText'
import FieldYesNoNull from './FieldYesNoNull'

export default {
    name: "FormBuilder",
    components: {
        CampoInput,
        CampoChoice,
        CampoYesNo,
        FieldSection,
        FieldMultiple,
        FieldEval,
        FieldText,
        FieldYesNoNull
    },
    props: [
        "schema",
        "debug",
        "mode"
    ],
    data() {
        return {
            dependencyMap: {},
            dependsOnMap: {},
            callbackMap: {}
        }
    },
    computed: {
        formData: {
            get() { return this.$attrs.value; },
            set(value) { this.$emit('update:value', value); }
        }
    },
    methods: {
        updateForm(field, value) {
            //add dependency resolution here
            //add eval here
            let fieldName = field.QuestionIdentifier;
            this.$set(this.formData, fieldName, value);
            this.$emit("input", this.formData);
            if(this.dependencyMap[fieldName]) {
                console.debug("updateForm: field " + fieldName + " has changed and has dependents, calling them back...");
                this.resolveDependency(field);
                //this.callbackMap[fieldName](this.formData);
            }

        },
        registerDependency(target, dependent) {
            console.debug("registerDependency: field " + dependent + " depends on " + target);
            if(this.dependencyMap[target] === undefined) {
                this.dependencyMap[target] = [dependent]
            } else {
                this.dependencyMap[target].push(dependent)
            }

            if(this.dependsOnMap[dependent] === undefined) {
                this.dependsOnMap[dependent] = [target]
            } else {
                this.dependsOnMap[dependent].push(target)
            }
            
        },
        findFieldFromName(fieldName) {
            return this.schema.fields.filter(field => field.QuestionIdentifier == fieldName)[0];
        },
        resolveDependency(field) {
            let fieldName = field.QuestionIdentifier;
            // call all fields affected by this change
            for(let affectedFieldName of this.dependencyMap[fieldName]) {
                console.debug("resolveDependency: calling field " + affectedFieldName + " callback")
                // callback, retval shows field.
                let cbRetVal = this.callbackMap[affectedFieldName](this.formData, this.updateForm);
                let affectedFieldObj = this.findFieldFromName(affectedFieldName);
                
                //ComputedValue - betterPos
                if(affectedFieldObj.ComputedValue) {
                    console.debug("resolveDependency: field " + fieldName + " affects Eval field " + affectedFieldName)
                    this.updateForm(affectedFieldObj, affectedFieldObj.ComputedValue);
                }
                //if field is not undefined or null, then show it even if it is shouldn't be visible
                //due to incorrect legacy imports
                if(cbRetVal.Visibility) {
                    affectedFieldObj.Show = true;
                    affectedFieldObj.ExternalValidation = { ForcedVisibility: false };
                } else if(this.formData[affectedFieldName]) {
                    affectedFieldObj.Show = true;
                    affectedFieldObj.ExternalValidation = { ForcedVisibility: true, ForcedVisibilityReason: "dependent of " + fieldName + " but value is not empty"};
                } else {
                    affectedFieldObj.Show = false;
                    affectedFieldObj.ExternalValidation = { ForcedVisibility: false };
                }
            }
        }
    },
    created() {
        if(this.$attrs.value) {
            console.debug("this value = true");
            this.formData = this.$attrs.value;
        } else {
            console.debug("this value = false");
            this.formData = {};
        }

        if(this.schema) {
            //console.log(JSON.stringify(this.schema));
            //mapear tipos de campos para componentes
            for(let field of this.schema.fields) {
                if(field.Attribute !== undefined) {
                    field.QuestionIdentifier = field.Attribute;
                } else if (field.QuestionNumber !== undefined) {
                    field.QuestionIdentifier = "qn" + String(field.QuestionNumber);
                } else {
                    field.QuestionIdentifier = "fs" + String(field.FormSequence);
                }

                     if(field.Type === 'input')     { field.Type = 'CampoInput'; }
                else if(field.Type === 'choice')    { field.Type = 'CampoChoice'; }
                //else if(field.Type === 'yesno')     { field.Type = 'CampoYesNo'; }
                else if(field.Type === 'yesno')     { field.Type = 'FieldYesNoNull'; }
                else if(field.Type === 'section')   { field.Type = 'FieldSection'; }
                else if(field.Type === 'multiple')  { field.Type = 'FieldMultiple'; }
                else if(field.Type === 'eval')      { field.Type = 'FieldEval'; } 
                else if(field.Type === 'text')      { field.Type = 'FieldText'; } 
                    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
                    // see window.Function()

                field.Show = true;
                if(field.DependsOn) {
                    // by default, don't show fields with dependencies.
                    field.Show = false;
                    for(let dependency of field.DependsOn) {
                        // VALIDATE DEPENDENCY
                        let dependencyFieldObj = this.findFieldFromName(String(dependency.Question));
                        if(dependencyFieldObj === undefined) {
                            console.error("field.DependsOn validation error: field " + String(dependency.Question) 
                                + " marked as a dependency of " + field.QuestionIdentifier + " does not exist. Skipping ");
                                continue;
                        }
                        this.registerDependency(String(dependency.Question), field.QuestionIdentifier);
                        
                        if(dependency.Rule === undefined) dependency.Rule = "$fieldValue !== undefined";
                        //if the Rule contains $fieldValue or $formData, consider advanced evaluation rules and assume a full conditional statement
                        if(!(dependency.Rule.search(/\$fieldValue/) > -1 || dependency.Rule.search(/\$formData/) > -1)) {
                            //no $fieldValue nor $formData in the Rule, so consider simple evaluation rules.
                            let fieldValVariable = "$fieldValue";
                            let ruleCondition;
                            /* castings */
                            // number casting
                            if(dependency.Rule.startsWith("n")) {
                                fieldValVariable = "$fieldValue && Number($fieldValue)";
                                ruleCondition = dependency.Rule.match(/n\s*(.*)/)[1];
                            } else if(dependency.Rule.startsWith("s")) {
                                //if dependency field has multiple type, match on whether the string is in the array
                                if(dependencyFieldObj.Type == "FieldMultiple") { //see above line 130. there's a map
                                    let cond = dependency.Rule.match(/s\s*(.*)/)[1].trim();
                                    if(cond.startsWith("==")) {
                                        let stringCompare = cond.match(/==\s*(.*)/)[1].trim();
                                        dependency.Rule = "$fieldValue && Array.from($fieldValue).includes(" + stringCompare + ")";
                                    } else {
                                        console.error("field.DependsOn error: field " + String(dependency.Question) 
                                            + " is multiple, can only compare strings as equality. (" + field.QuestionIdentifier + ")");                                        
                                        continue;
                                    }
                                    
                                } else {
                                    fieldValVariable = "$fieldValue && String($fieldValue)";
                                    ruleCondition = dependency.Rule.match(/s\s*(.*)/)[1];
                                }                               
                            } else if(dependency.Rule.startsWith("b")) {
                                fieldValVariable = "$fieldValue && Boolean($fieldValue)";
                                ruleCondition = dependency.Rule.match(/b\s*(.*)/)[1];
                            } else if(dependency.Rule.startsWith("v")) {
                                fieldValVariable = "$fieldValue";
                                ruleCondition = dependency.Rule.match(/v\s*(.*)/)[1];
                            }

                            if(ruleCondition) {
                                dependency.Rule = fieldValVariable + " " + ruleCondition;
                            }
                        }
                        //compile evaluation rule
                        let evalRuleCode = '"use strict"; if(' + dependency.Rule + '){ return true; } return false;';
                        //let evalRuleCode = '"use strict"; debugger; if(' + dependency.Rule + '){ return true; } return false;';
                        console.debug("evalRuleCode = " + evalRuleCode);
                        dependency.EvalRule = new Function('$fieldValue', '$formData', evalRuleCode);
                    }

                    if(field.Compute) {
                        const utils = "\
                            function fixedDecimal(n, d) { return +n.toFixed(d); } \
                            function toNumber(v) { return Number.parseFloat(v); } \
                        ";
                        let computeFnCode = '"use strict";' + utils + field.Compute;
                        console.debug("computeFnCode = " + computeFnCode);
                        field.ComputeFn = new Function('$formData', computeFnCode);
                    }

                    /* Callback Function is defined below */
                    this.callbackMap[field.QuestionIdentifier] =  function(formData, updateFormCallback) {
                        // this is an equivalent of an AND operation
                        for(let dependency of field.DependsOn) {
                            console.debug("dependencyCallbackValidation for " + field.QuestionIdentifier + ": formData[" + dependency.Question + "] = " + formData[dependency.Question]);        
                            let evalRuleResult = dependency.EvalRule(formData[dependency.Question], formData);
                            console.debug("dependencyCallbackValidation for " + field.QuestionIdentifier + ": .EvalRule(" + formData[dependency.Question] + ") = " + evalRuleResult);
                            if(!evalRuleResult) {
                                console.debug("dependencyCallbackValidation for " + field.QuestionIdentifier + ": formData[" + dependency.Question + "] is false, returning false");
                                return {
                                    Visibility: false,
                                    Value: undefined
                                }
                            }
                        }

                        //all parents have defined values, now evaulate the visibility rule
                        let computed = undefined;
                        if(field.ComputeFn) {
                            computed = field.ComputeFn(formData);
                            //trigger input change
                            //if(updateFormCallback){
                            //    updateFormCallback(field, computed);
                            //}
                        }
                        console.debug("computedValue for " + field.QuestionIdentifier + ": " + computed);
                        field.ComputedValue = computed;
                        return {
                            Visibility: true,
                            Value: computed
                        };
                    };
                    /* End Callback Function */
                }// end if(field.dependsOn)
            }//end for(field of this.schema.fields)

            //now run all dependencies
            console.log("schema load: calling all dependencies");
            for(let field of this.schema.fields) {
                if(this.dependencyMap[field.QuestionIdentifier]) {
                    this.resolveDependency(field);
                }
            }
        } //end if(this.schema)
    }
}
</script>