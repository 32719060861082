<template>
    <sui-header dividing>{{ Label }}</sui-header>
</template>
<script>
export default {
    name: "FieldSection",
    props: [
        "Label",
        "mode"
    ]
}
</script>