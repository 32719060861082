<template>
  <div>
    <h1 v-if="!loaded"><sui-button icon="chevron left" @click="$router.go(-1)" />Paciente</h1>
    <sui-loader active v-if="!loaded" />
    <div v-if="loaded">
        <h1><sui-button icon="chevron left" @click="$router.go(-1)" />{{ schema.Patient.Name }}</h1> <!-- TODO: adicionar botão para editar nome -->
        <h4 v-if="schema.Patient.ExeterNumber == null">Sem Exeter cadastrado</h4>
        <h4 v-else>Ex{{ schema.Patient.ExeterNumber}}</h4>
        <sui-accordion styled v-if="schema.PatientNames.length > 1">
          <sui-accordion-title>
            <sui-icon name="dropdown" />Outros nomes
          </sui-accordion-title>
          <sui-accordion-content>
            <ul>
              <li v-for="pn in schema.PatientNames" :key="pn.PatientNameId">{{ pn.Name }}</li>
            </ul>
          </sui-accordion-content>
        </sui-accordion>

        <sui-dropdown 
          class="labeled icon"
          icon="file alternate outline"
          button
          text="Nova ficha"
        >
          <sui-dropdown-menu>
            <sui-dropdown-item v-for="form in mappedProtocols" :key="form.FormId">
              <router-link 
                :to="{ name: 'Record', params: { id: form.FormId, action: 'new', target: schema.Patient.PatientId }}" 
              >
                {{form.FormName}}
              </router-link>
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>

        <sui-button content="Imagens" icon="camera" label-position="left" @click="$router.push({name: 'PatientImages', params: {id: $route.params.id }});" />
        <sui-button content="Editar paciente" icon="edit outline" label-position="left" 
          @click="$router.push({name: 'NewPatient', params: {
              patientId: schema.Patient.PatientId, 
              patientName: schema.Patient.Name,
              exeter: schema.Patient.ExeterNumber
            }
          });" 
        />

        <sui-table celled compact="very">
          <sui-table-header>
            <sui-table-row>
              <sui-table-header-cell collapsing>Ficha</sui-table-header-cell>
              <sui-table-header-cell>Nome na ficha</sui-table-header-cell>
              <sui-table-header-cell>Data</sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>
          <sui-table-body>
            <sui-table-row v-for="record in schema.Records" :key="record.RecordId">
              <sui-table-cell collapsing><router-link :to="{ name: 'Record', params: { id: record.RecordId, action: 'view' }}" >{{record.FormName}}</router-link></sui-table-cell>
              <sui-table-cell>{{ record.DynName }}</sui-table-cell>
              <sui-table-cell>{{ record.RecordDate }}</sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
    </div>
  </div>
</template>

<script>
/*eslint no-unused-vars: off */
import { getPatient, listProtocolForms } from '../BisturiDB'
export default {
  name: "PatientDetail",
  components: { },
  data() {
    return {
      loaded: false,
      schema: null,
      mappedProtocols: null,
      selectedNewProtocol: null,
      patientEdit: false,
      exeterEdit: false
    }
  },
  async created () {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    async fetchData() {
      let patientId = this.$route.params.id;
      //this.schema = await getPatient(patientId);
      //this.mappedProtocols = await listProtocolForms();
      [this.schema, this.mappedProtocols] = await Promise.all([getPatient(patientId), listProtocolForms()]);
      this.loaded = true;
    }
  }
};
</script>`